import './Certification.scss';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser'; 

const Certification = () => {
  const { t } = useTranslation();

  return (
    <div className='Certification'>
      <div className="title-Certification">
        <h1>{t('certification.title')}</h1>
      </div>

      <div className="container">
        <div className="box-Certification">
          <img src="/HMQCERTIFIED.webp" alt="logo" /><br />
          <p>{parse(t('certification.description'))}</p>
          <br />
          <h2>{t('certification.certification_activity.title')}</h2>
          <br />
          <p>{parse(t('certification.certification_activity.intro'))}</p>
          <ul>
            <li><b>{t('certification.certification_activity.training.title')}</b> {t('certification.certification_activity.training.description')}</li>
            <li><b>{t('certification.certification_activity.traceability.title')}</b> {t('certification.certification_activity.traceability.description')}</li>
            <li><b>{t('certification.certification_activity.quality_control.title')}</b> {t('certification.certification_activity.quality_control.description')}</li>
            <li><b>{t('certification.certification_activity.legal.title')}</b> {t('certification.certification_activity.legal.description')}</li>
          </ul>
          <p>{parse(t('certification.conclusion'))}</p>
        </div>
      </div>
    </div>
  );
}

export default Certification;
