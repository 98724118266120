import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './NavBar.scss';
import { useTranslation } from 'react-i18next';

const NavBar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { t } = useTranslation();

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header className='header'>
      <div className='logo'>
      <img src="/HMQ.png" width="600" height="400" alt="logo de la page"/>
      </div>
      <nav className={`navbar ${isMobileMenuOpen ? 'open' : ''}`}>
        <Link to="/" onClick={toggleMobileMenu}>{t('navbar.home')}</Link>
        <Link to="/Presentación" onClick={toggleMobileMenu}>{t('navbar.presentation')}</Link>
        <Link to="/Certificación" onClick={toggleMobileMenu}>{t('navbar.certification')}</Link>
        <Link to="/contact" onClick={toggleMobileMenu}>{t('navbar.contact')}</Link>
      </nav>
      <div className='burger' onClick={toggleMobileMenu}>
        <div className='line1'></div>
        <div className='line2'></div>
        <div className='line3'></div>
      </div>
    </header>
  );
}

export default NavBar;
