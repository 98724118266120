import React, { useState, useEffect } from 'react';
import './CookieConsent.scss';

const CookieConsent = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true');
    setVisible(false);
  };
  const handleDecline = () => {
    localStorage.setItem('cookieConsent', 'false');
    setVisible(false);
  };

  return (
    visible && (
      <div className="cookie-consent">
        <p>Nous utilisons des cookies pour améliorer votre expérience. En acceptant  votre navigation, vous acceptez leur utilisation.</p>
        <div className="cookie-consent-buttons">
          <button onClick={handleAccept}>Accepter</button>
          <button onClick={handleDecline}>Refuser</button>
        </div>
      </div>
    )
  );
};

export default CookieConsent;
