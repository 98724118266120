import './App.scss';
import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import NavBar from './Components/NavBar/NavBar';
import Footer from './Components/Footer/Footer';
import Presentation from './Components/Presentation/Presentation';
import Certification from './Components/Certification/Certification';
import CookieConsent from './Components/CookieConsent/CookieConsent';
import LanguageSelector from './Components/LangageSelector/LangageSelector';


// Utilisation de React.lazy pour le code splitting
const Hero = React.lazy(() => import('./Components/Hero/Hero'));
const Mission = React.lazy(() => import('./Components/Mission/Mission'));
const Article = React.lazy(() => import('./Components/Article/Article'));
const Contact = React.lazy(() => import('./Components/Contact/Contact'));
const BienestarAnimal = React.lazy(() => import('./Components/Article/Leermás/BienestarAnimal/BienestarAnimal'));
const CertificacionHalal = React.lazy(() => import('./Components/Article/Leermás/CertificacionHalal/CertificacionHalal'));
const HalalAdoracion = React.lazy(() => import('./Components/Article/Leermás/HalalAdoracion/HalalAdoracion'));
const Partenaires = React.lazy(() => import('./Components/Partenaires/Partenaires'));

const App = () => {
  const location = useLocation();

  return (
    <div>
      <CookieConsent />
      <header id="page-d-accueil">
        <NavBar />
        <LanguageSelector />
        {location.pathname === '/' && (
          <section id='hero'>
            <Suspense fallback={<div>Loading...</div>}>
              <Hero type="hero" />
            </Suspense>
          </section>
        )}
      </header>
      <main>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route exact path="/" element={
              <>
                <section id='service'>
                  <Mission type="services"/>
                </section>
                <section id='article'>
                  <Article type="article"/>
                </section>
                <section id='partenaires'>
                  <Partenaires />
                </section>
              </>
            } />
            <Route path="/contact" element={<Contact />} />
            <Route path="/Presentación" element={<Presentation />} />
            <Route path="/Certificación" element={<Certification />} />
            <Route path="/bienestar-animal" element={<BienestarAnimal />} />
            <Route path="/certificacion-halal" element={<CertificacionHalal />} />
            <Route path="/halal-adoracion" element={<HalalAdoracion />} />
          </Routes>
        </Suspense>
      </main>
      <Footer />
    </div>
  );
}

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
