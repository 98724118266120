import englishFlag from '../../assets/english-flag.webp';
import frenchFlag from '../../assets/french-flag.webp';
import spanishFlag from '../../assets/spanish-flag.webp';
import { useTranslation } from 'react-i18next';
import './LangageSelector.scss';

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    console.log(`Changing language to: ${lng}`); // Log pour déboguer
    i18n.changeLanguage(lng)
      .then(() => {
        console.log(`Language changed to: ${i18n.language}`); // Log pour confirmer le changement
      })
      .catch((error) => {
        console.error(`Failed to change language to: ${lng}`, error); // Log en cas d'erreur
      });
  };

  return (
    <div className="language-selector">
      <button 
  className={`en ${i18n.language === 'en' ? 'active' : ''}`}
  onClick={() => changeLanguage('en')}
  style={{ backgroundImage: `url(${englishFlag})` }}
>
  English
</button>
<button 
  className={`fr ${i18n.language === 'fr' ? 'active' : ''}`}
  onClick={() => changeLanguage('fr')}
  style={{ backgroundImage: `url(${frenchFlag})` }}
>
  Français
</button>
<button 
  className={`es ${i18n.language === 'es' ? 'active' : ''}`}
  onClick={() => changeLanguage('es')}
  style={{ backgroundImage: `url(${spanishFlag})` }}
>
  Español
</button>
    </div>
  );
};

export default LanguageSelector;