import './Presentation.scss';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

const Presentation = () => {
  const { t } = useTranslation();

  return (
    <div className='Presentation'>
      <div className="title-Presentation">
        <h1>{t('presentation.title')}</h1>
      </div>

      <div className="container">
        <div className="box-Presentation">
          <img src="/logo.webp" alt="logo" /><br />
          <p>{parse(t('presentation.intro'))}</p>
          <br />
          <h2>{t('presentation.our_commitments.title')}</h2>
          <br />
          <p>{parse(t('presentation.our_commitments.description'))}</p>
          <br />
          <h2>{parse(t('presentation.certification.title'))}</h2>
          <br />
          <p>{parse(t('presentation.certification.description'))}</p>
          <br />
          <h2>{parse(t('presentation.partners.title'))}</h2>
          <br />
          <p>{parse(t('presentation.partners.description'))}</p>
          <br />
          <h2>{parse(t('presentation.why_choose.title'))}</h2>
          <br />
          <p>{parse(t('presentation.why_choose.description'))}</p>
          <br />
          <h2>{t('presentation.conclusion.title')}</h2>
          <br />
          <p>{parse(t('presentation.conclusion.description'))}</p>
          <br />
          <p>{t('presentation.closing_thank_you')}</p>
          <br />
          <p>{t('presentation.contact_info')}</p>
          <br /><br />
        </div>
      </div>
    </div>
  );
}

export default Presentation;
